import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
function Nav({ Tab }) {
  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer");
    const menuicon = document.getElementById("menuIcon");
    menuicon.classList.toggle("close-button");
    menu.classList.toggle("open-menu");
  }
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
    setTimeout(() => {
      const bookCallElement = document.getElementById("bookcall");
      if (bookCallElement) {
        bookCallElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const handleClick2 = () => {
    navigate("/");
    setTimeout(() => {
      const bookCallElement = document.getElementById("team");
      if (bookCallElement) {
        bookCallElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const handleClick3 = () => {
    navigate("/");
    setTimeout(() => {
      const bookCallElement = document.getElementById("services");
      if (bookCallElement) {
        bookCallElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const handleClick4 = () => {
    navigate("/");
    setTimeout(() => {
      const bookCallElement = document.getElementById("results");
      if (bookCallElement) {
        bookCallElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const navClass = scrolled ? "activenav" : "";
  return (
    <>
      <div className="mobile-nav" id="mobileNavContainer">
        <div className="mobile-nav-inner">
          <a href="/#home" onClick={() => toggleMenu()}>
            HOME
          </a>
          <div className="divider"></div>
          <a href="/#results" onClick={() => toggleMenu()}>
            RESULTS
          </a>
          <div className="divider"></div>
          <a href="/#services" onClick={() => toggleMenu()}>
            SERVICES
          </a>
          <div className="divider"></div>
          <a href="/#team" onClick={() => toggleMenu()}>
            TEAM
          </a>
          <div className="divider"></div>
          <a href="/about" onClick={() => toggleMenu()}>
            ABOUT US
          </a>
          <div className="divider"></div>
          <a href="/apply-now" onClick={() => toggleMenu()}>
            APPLY NOW
          </a>
          <div className="divider"></div>
          <a href="/contact" onClick={() => toggleMenu()}>
            CONTACT
          </a>
          <div className="divider"></div>
          {/* <div className='social-icons'>

                        <a href="https://t.me/" target="_blank" rel="noreferrer"><img src="/assets/telegram.svg" alt="" /></a>
                        <a href="https://discord.gg/" target="_blank" rel="noreferrer"><img src="/assets/discord.svg" alt="" /></a>
                        <a href="https://twitter.com/" target="_blank" rel="noreferrer"><img src="/assets/twitter.svg" alt="" /></a>
                    </div> */}
        </div>
      </div>
      <div className="menu-button" id="menuIcon" onClick={() => toggleMenu()}>
        <div className="line-top"></div>
        <div className="line-bottom"></div>
      </div>
      <nav className={navClass}>
        <div className="nav-wrap">
          <div className="links hide-800">
            <a href="/#home">HOME</a>
            <a onClick={handleClick4} href="#results">
              RESULTS
            </a>
            <a onClick={handleClick3} href="#services">
              SERVICES
            </a>
            <a onClick={handleClick2} href="#team">
              TEAM
            </a>
          </div>
          <div className="flexaudi">
            <a href="/#home">
              <div className="mainlogowrap">
                <img src="/logo.png" className="mainlogo" alt="" />
              </div>
            </a>
          </div>
          <div className="links hide-800">
            <Link to="/about">ABOUT US</Link>
            <Link to="/apply-now">APPLY NOW</Link>
            <Link to="/contact">CONTACT</Link>
          </div>
        </div>
        {Tab === "home" ? (
          <a className="noanim buttoncall hide-800" href="#bookcall">
            <button className="applynow-nav">BOOK A CALL</button>
          </a>
        ) : (
          <div className="noanim buttoncall hide-800" onClick={handleClick}>
            <button className="applynow-nav">BOOK A CALL</button>
          </div>
        )}
      </nav>
    </>
  );
}

export default Nav;
