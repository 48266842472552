import "../App.css";
import Nav from "../components/Nav";

function About() {
  const currentTab = "about";

  return (
    <>
      <Nav Tab={currentTab}></Nav>
      <div className="project startout aboutus">
        <div className="title">
          <div className="label">
            OUR STORY
            <hr></hr>
          </div>
          <h1>ABOUT US</h1>
          <p>
            Branden had always been passionate about marketing. Even as a kid,
            he was fascinated by the commercials he saw on television and the
            ads he found in magazines. He loved the way marketers used their
            creativity and ingenuity to capture people's attention and persuade
            them to buy products.
            <br />
            <br />
            Branden secured a position at a prominent advertising agency, where
            he swiftly climbed the ranks, earning respect as a valued team
            member. However, amidst his success, he found himself restrained by
            the lack of attention that personal influencers received.
            <br />
            <br />
            One day, Branden decided to take a leap of faith and start his own
            marketing agency. He knew it would be a risky move, but he felt
            confident that he had the skills and the vision to make it work.
            <br />
            <br />
            He spent months researching the industry, networking with other
            professionals, and developing a business plan.
            <br />
            <br />
            In the early days, Branden worked tirelessly to build his brand and
            attract clients. He spent long hours brainstorming ideas, creating
            marketing materials, and reaching out to potential customers. He
            took on every project that came his way, no matter how small or how
            challenging.
            <br />
            <br />
            Slowly but surely, Branden's agency began to grow. He hired a team
            of talented marketers, designers, and writers to help him handle the
            workload, and he invested in the latest technology and software to
            keep his company ahead of the curve.
            <br />
            <br />
            Over time, Branden's reputation as a marketing guru grew, and his
            agency became known for its innovative campaigns and creative
            solutions. Today, Branden's agency is one of the most successful in
            the industry, and he is proud of the hard work and determination
            that got him there.
          </p>
        </div>
        <div className="progress">
          <div className="progress-phase">
            <h2>HOW IT STARTED</h2>
            <img src="/assets/started.jpg" alt="" />
            <h3>2022 - Branden's startup office</h3>
          </div>
          <div className="progress-phase">
            <h2>HOW IT'S GOING</h2>
            <img src="/assets/current.jpg" alt="" />
            <h3>2024 - Our current NYC location</h3>
          </div>
          <div className="progress-phase">
            <h2>OUR FUTURE</h2>
            <img src="/assets/future.jpg" alt="" />
            <h3>2025 - Additional studio location in Miami, Florida</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
