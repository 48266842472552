
import Nav from '../components/Nav';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Contact() {
    const currentTab = "contact";
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_dg60h2x', "template_ueyvmtr", form.current, 'PlGta2j1xU0Ch_u3J')
            .then((result) => {
                console.log(result.text);
                toast.success(`Your message was sent.`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }, (error) => {
                console.log(error.text);
                toast.error(`Your message was not sent.`, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
        document.getElementById('name').value = '';
        document.getElementById('email').value = '';
        document.getElementById('comment').value = '';

    };
    return (
        <>
            <Nav Tab={currentTab}></Nav>
            <div className='contact-page'>
                <div className="project">
                    <div className="title">
                        <div className="label">
                            REACH OUT
                            <hr></hr>
                        </div>
                        <h1>Contact</h1>
                        <p >
                            For questions or concerns, simply fill out our contact form to get in touch.
                        </p>
                    </div>
                    <div class="contact-form" >
                        <span class="title">Contact us</span>
                        <form class="form" ref={form} onSubmit={sendEmail}>
                            <div class="group">
                                <input placeholder="" type="text" id="name" name="name" required />
                                <label for="name">Name</label>
                            </div>
                            <div class="group">
                                <input placeholder="" type="email" id="email" name="email" required />
                                <label for="email">Email</label>
                            </div>
                            <div class="group">
                                <textarea placeholder="" id="comment" name="comment" rows="5" required></textarea>
                                <label for="comment">Message</label>
                            </div>
                            <button type="submit" className='applynow' value="Send">Send</button>
                        </form>
                    </div>

                </div>
            </div>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
}

export default Contact;
